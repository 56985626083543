<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ADMIN SUMMARY COLLECTION REPORT</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col md="12" cols="12"></v-col>
        <v-col md="6" cols="12">
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center text-uppercase">Branch</th>
                <th class="text-center text-uppercase"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list_of_summary_collection_items" :key="item.id">
                <td>{{ item.branch_code }}</td>
                <td>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center text-uppercase">Name</th>
                          <th class="text-center text-uppercase">Deposited</th>
                          <th class="text-center text-uppercase">Net Collection</th>
                          <th class="text-center text-uppercase">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item2 in item.data" :key="item2.id">
                          <td>{{ item2.name }}</td>
                          <td class="text-center">
                            {{ item2.total_deposit }}
                          </td>
                          <td class="text-center">
                            {{ item2.total_net }}
                          </td>
                          <td class="text-center">
                            {{ item2.total_difference }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color" :snackbar_text="this.snackbar_text" />
  </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    month_of: '',
    month_of_items: [],

    list_of_summary_collection_items: [],
    is_deleting: false,
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPrinter,
      },
    }
  },
  created() {
    this.get_transaction_month_history({
      branch_id: 1,
    })
      .then(response => {
        this.month_of_items = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('transaction_months_history', ['get_transaction_month_history']),
    ...mapActions('deposit_slip', ['admin_remittances_data']),
    monthly_remittances() {
      const data = new FormData()
      data.append('month_of', this.month_of)
      this.admin_remittances_data(data)
        .then(response => {
          this.list_of_summary_collection_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    print_data(index) {
      var imgData = this.company_logo
      var payments_array = []
      var header_array = []

      var month = this.month_of
      var date_credited = this.list_of_summary_collection_items[index].date_credited
      var credited_to = this.list_of_summary_collection_items[index].credited_to
      var voucher_no = this.list_of_summary_collection_items[index].voucher_no
      var prev_balance = 0
      var amount = this.list_of_summary_collection_items[index].amount
      var balance = this.list_of_summary_collection_items[index].balance
      var total_amount = 0

      if (index - 1 >= 0) {
        prev_balance = this.list_of_summary_collection_items[index - 1].balance
      }
      header_array.push([
        { text: 'Date Credited', alignment: 'center', style: 'label' },
        { text: 'Credited To', alignment: 'center', style: 'label' },
        { text: 'Voucher #', alignment: 'center', style: 'label' },
        { text: 'Prev Balance', alignment: 'center', style: 'label' },
        { text: 'Amount', alignment: 'center', style: 'label' },
        { text: 'Balance', alignment: 'center', style: 'label' },
        { text: '', alignment: 'center', style: 'label' },
      ])
      header_array.push([
        { text: date_credited, alignment: 'center' },
        { text: credited_to, alignment: 'center' },
        { text: voucher_no, alignment: 'center' },
        { text: prev_balance, alignment: 'center' },
        { text: amount, alignment: 'center' },
        { text: balance, alignment: 'center' },
        { text: '', alignment: 'center' },
      ])
      payments_array.push([
        { text: 'ID', alignment: 'center', style: 'label' },
        { text: 'Payee', alignment: 'center', style: 'label' },
        { text: 'Particulars', alignment: 'center', style: 'label' },
        { text: 'OR Date', alignment: 'center', style: 'label' },
        { text: 'OR #', alignment: 'center', style: 'label' },
        { text: 'Description', alignment: 'center', style: 'label' },
        { text: 'FSC #', alignment: 'center', style: 'label' },
        { text: 'Name', alignment: 'center', style: 'label' },
        { text: 'Amount', alignment: 'center', style: 'label' },
      ])
      if (this.list_of_summary_collection_items[index].liquidations.length > 0) {
        this.list_of_summary_collection_items[index].liquidations.forEach(function (item, index, payment) {
          total_amount = total_amount + parseFloat(item.amount)
          payments_array.push([
            { text: item.id, alignment: 'left' },
            { text: item.payee, alignment: 'left' },
            { text: item.particulars.particulars, alignment: 'left' },
            { text: item.or_date, alignment: 'left' },
            { text: item.or_no, alignment: 'left' },
            { text: item.description, alignment: 'left' },
            { text: item.deceased === null ? '' : item.deceased.fsc_no, alignment: 'left' },
            {
              text: item.deceased === null ? '' : item.deceased.last_name + ', ' + item.deceased.first_name,
              alignment: 'left',
            },
            {
              text: (item.amount / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              { text: '', alignment: 'left', border: [false, false, false, false] },
              { text: '', alignment: 'left', border: [false, false, false, false] },
              { text: '', alignment: 'left', border: [false, false, false, false] },
              { text: '', alignment: 'left', border: [false, false, false, false] },
              { text: '', alignment: 'left', border: [false, false, false, false] },
              { text: '', alignment: 'left', border: [false, false, false, false] },
              { text: '', alignment: 'left', border: [false, false, false, false] },
              {
                text: 'Total:',
                color: 'red',
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: (total_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                color: 'red',
                alignment: 'left',
                border: [false, false, false, false],
              },
            ])
          }
        })
      } else {
        payments_array.push(['----', '----', '----', '----', '----', '----', '----', '----', '----'])
      }
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        content: [
          {
            columns: [
              { image: imgData, width: 54, height: 54, style: 'logo' },
              {
                stack: [
                  'GOODLIFE MEMORIAL CHAPEL CO.',
                  { text: this.branch_address, style: 'subheader' },
                  {
                    text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader',
                  },
                ],
                style: 'header',
              },
            ],
          },
          { text: 'CHAPEL FUND SUMMARY REPORT', style: 'title' },
          '============================================================================================================',
          { text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label' },
          { text: 'Branch: ' + this.branch, style: 'main_info' },
          { text: 'Month of: ' + month, style: 'main_info' },
          {
            style: 'tableExample',
            table: {
              widths: [92, 92, 92, 92, 92, 92, 92],
              body: header_array,
            },
            layout: {
              fillColor: function (rowIndex) {
                return rowIndex === 0 ? '#d9b4b4' : null
              },
              hLineWidth: function (i, node) {
                return i == 0 || i === node.table.body.length ? 1 : 0
              },
              vLineWidth: function (i, node) {
                return i == 0 || i === node.table.widths.length ? 1 : 0
              },
            },
          },
          ' ',
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: [40, 50, 90, 60, 40, 80, 40, 160, 60],
              body: payments_array,
            },
            layout: {
              fillColor: function (rowIndex) {
                return rowIndex === 0 ? '#CCCCCC' : null
              },
            },
          },
          {
            stack: [
              {
                columns: [
                  { text: 'Prepared By: ', style: 'aa_top_margin' },
                  { text: 'Received By:', style: 'cashier_top_margin' },
                ],
              },
              {
                columns: [
                  { text: '', style: 'aa_for' },
                  { text: '', style: 'cashier_for' },
                ],
              },
              {
                columns: [
                  { text: '_________________________________', style: 'aa_line' },
                  { text: '_________________________________', style: 'cashier_line' },
                ],
              },
              {
                columns: [
                  { text: 'Cashier', style: 'aa_placeholder' },
                  { text: 'Name', style: 'cashier_placeholder' },
                ],
              },
            ],
          },
          {
            stack: [
              {
                columns: [
                  { text: 'Verified By: ', style: 'cs_top_margin' },
                  { text: 'Audited By: ', style: 'au_top_margin' },
                ],
              },
              {
                columns: [
                  { text: '', style: 'cs_for' },
                  { text: '', style: 'au_for' },
                ],
              },
              {
                columns: [
                  { text: '_________________________________', style: 'bm_line' },
                  { text: '_________________________________', style: 'au_line' },
                ],
              },
              {
                columns: [
                  { text: 'Chapel Manager', style: 'bm_placeholder' },
                  { text: 'Auditor', style: 'au_placeholder' },
                ],
              },
            ],
          },
        ],
        // footer: {
        //   columns: [
        //     {
        //       text: 'Generated with Goodlife Savings & Credit Cooperative System',
        //       alignment: 'center',
        //       style: 'tableExample'
        //     }
        //   ]
        // },
        styles: {
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 20], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 10,
          },
          title: {
            fontSize: 15,
            alignment: 'center',
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 4, 0, 0], //[left, top, right, bottom]
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
          },
          sub_info: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
          },
          tableExample: {
            fontSize: 9,
          },

          aa_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          bm_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          cs_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          aa_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          aa_placeholder: {
            margin: [15, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          aa_for: {
            margin: [0, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_for: {
            margin: [2, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_for: {
            margin: [3, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_for: {
            margin: [3, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_placeholder: {
            margin: [26, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_placeholder: {
            margin: [2, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_placeholder: {
            margin: [5, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          au_placeholder: {
            margin: [2, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_for: {
            margin: [2, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
    },
  },
}
</script>
